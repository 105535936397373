import { PrivacyOption } from '../types'

export enum PrivacyLevel {
  PUBLIC = 1,
  ME = 2,
  DISTRICT = 3,
  SCHOOL = 4,
  NETWORK = 5,
  TEAM = 6,
}

export enum PrivacyLabel {
  PUBLIC = 'Share With The Community (recommended)',
  ME = 'Make Private To Me',
  DISTRICT = 'Make Private To My District',
  SCHOOL = 'Make Private To My School',
  NETWORK = 'Make Private To My Network',
  TEAM = 'Make This Material Private To Team',
}

const PRIVACY_OPTIONS = {
  SHARE_WITH_COMMUNITY: {
    privacyLabel: PrivacyLabel.PUBLIC,
    privacyLevel: PrivacyLevel.PUBLIC,
  },
  PRIVATE_TO_ME: {
    privacyLabel: PrivacyLabel.ME,
    privacyLevel: PrivacyLevel.ME,
  },
  PRIVATE_TO_DISTRICT: {
    privacyLabel: PrivacyLabel.DISTRICT,
    privacyLevel: PrivacyLevel.DISTRICT,
  },
  PRIVATE_TO_SCHOOL: {
    privacyLabel: PrivacyLabel.SCHOOL,
    privacyLevel: PrivacyLevel.SCHOOL,
  },
  PRIVATE_TO_NETWORK: {
    privacyLabel: PrivacyLabel.NETWORK,
    privacyLevel: PrivacyLevel.NETWORK,
  },
  PRIVATE_TO_TEAM: {
    privacyLabel: PrivacyLabel.TEAM,
    privacyLevel: PrivacyLevel.TEAM,
  },
}

const getTeamPrivacyOptions = (): PrivacyOption[] => {
  const teamOptions = [
    PRIVACY_OPTIONS.SHARE_WITH_COMMUNITY,
    PRIVACY_OPTIONS.PRIVATE_TO_TEAM,
  ]

  return teamOptions
}

const getNonTeamPrivacyOptions = (
  isNetwork: boolean,
  hasOrIsNetwork: boolean
): PrivacyOption[] => {
  const nonTeamOptions = [
    PRIVACY_OPTIONS.SHARE_WITH_COMMUNITY,
    PRIVACY_OPTIONS.PRIVATE_TO_ME,
    PRIVACY_OPTIONS.PRIVATE_TO_SCHOOL,
  ]

  if (isNetwork === false) {
    nonTeamOptions.push(PRIVACY_OPTIONS.PRIVATE_TO_DISTRICT)
  }

  if (hasOrIsNetwork === true) {
    nonTeamOptions.push(PRIVACY_OPTIONS.PRIVATE_TO_NETWORK)
  }

  if (isNetwork === true || hasOrIsNetwork === true) {
    nonTeamOptions.push(PRIVACY_OPTIONS.PRIVATE_TO_DISTRICT)
    nonTeamOptions.push(PRIVACY_OPTIONS.PRIVATE_TO_NETWORK)
  }

  return nonTeamOptions
}

export const getPrivacyOptions = (
  value: string,
  isNetwork: boolean,
  hasOrIsNetwork: boolean
): PrivacyOption[] => {
  // Check if selected owner is a team (i.e. starts with t)
  const pattern = /^t\d/

  if (pattern.test(value)) {
    return getTeamPrivacyOptions()
  } else {
    return getNonTeamPrivacyOptions(isNetwork, hasOrIsNetwork)
  }
}

export const defaultValue = 'show_default'
export const pathwayInputName = 'pathwayId'
export const classInputName = 'classId'
export const subjectInputName = 'subjectId'
export const scaleInputName = 'scaleId'
