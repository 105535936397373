import { Controller, FieldError, useFormContext } from 'react-hook-form'
import { SimpleSelect, Text } from '@instructure/ui'
import type { FormMessage } from '@instructure/ui-form-field'
import { selectorAttrs } from '../../../../../mc-ui/utils/selectorAttrs'
import {
  classInputName,
  defaultValue,
  pathwayInputName,
  subjectInputName,
} from '../../../helpers/constants'
import { RequiredLabel } from '../../RequiredLabel'
import { useSubjects } from '../../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

interface Props {
  subjectId: string
  setShowAllPathways: (showAllPathways: boolean) => void
}

export const SubjectSelector = ({ subjectId, setShowAllPathways }: Props) => {
  const {
    formState: { errors },
    resetField,
  } = useFormContext()

  const subjects = useSubjects() || []

  const handleSubjectChange = (onChange, event: React.SyntheticEvent, data) => {
    const selectedSubjectId = data.value as string
    onChange(selectedSubjectId)
    resetField(pathwayInputName)
    resetField(classInputName)
    setShowAllPathways(false)
  }

  function formatErrorMessage(errorMessage: string): FormMessage[] {
    return [
      {
        text: (<Text>{errorMessage}</Text>) as React.ReactNode,
        type: 'error',
      },
    ]
  }

  function handleErrorMessages(error: FieldError): FormMessage[] {
    if (error?.type === 'minLength' || error?.type === 'validate') {
      const message = 'Subject cannot be blank'
      return formatErrorMessage(message)
    }
    return []
  }

  return (
    <Controller
      name={subjectInputName}
      defaultValue={subjectId}
      rules={{
        minLength: 1,
        required: true,
        validate: (value) => value !== defaultValue,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <SimpleSelect
          renderLabel={RequiredLabel('Subject')}
          id={subjectInputName}
          messages={handleErrorMessages(errors[subjectInputName] as FieldError)}
          {...selectorAttrs('cmap-subject-input')}
          onChange={(event: React.SyntheticEvent, data) => {
            handleSubjectChange(onChange, event, data)
          }}
          value={value}
          inputRef={ref}
          onBlur={onBlur}
        >
          <SimpleSelect.Option
            id={defaultValue}
            value={defaultValue}
            {...selectorAttrs('subject-input-default-input')}
          >
            Select Subject
          </SimpleSelect.Option>
          {subjects.map(({ name, id }) => (
            <SimpleSelect.Option
              key={id}
              id={String(id)}
              value={String(id)}
              {...selectorAttrs(`subject-input-${id}-input`)}
            >
              {String(name)}
            </SimpleSelect.Option>
          ))}
        </SimpleSelect>
      )}
    />
  )
}
