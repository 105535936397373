import { AbstractResource } from '../AbstractResource'

type CreateFromClassroomRequestBody = {
  classroomId: number | string
  curriculumMap: {
    title: string
    description?: string
    collectionId: number
    restrictCloning: boolean
  }
  ownerId: number | string
}

type CreateFromClassroomResponseBody = {
  curriculumMapId: number
}

class CreateFromClassroomResource extends AbstractResource {
  _endpointUrl = '/curriculum_maps/create_from_classroom'
  _snakeCaseKeys = true
  _camelizeKeys = true

  create(
    data: CreateFromClassroomRequestBody
  ): Promise<CreateFromClassroomResponseBody> {
    return super.create(data)
  }
}

export {
  CreateFromClassroomResource,
  CreateFromClassroomRequestBody,
  CreateFromClassroomResponseBody,
}
