import { ComponentClass, ComponentType } from 'react'
import { ComponentTheme, BaseTheme } from '@instructure/shared-types'
import { withStyle as instUiWithStyle } from '@instructure/ui'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type WithStyleHOC<TStyles> = <TOwnProps>(
  Component: ComponentType<TOwnProps>
) => ComponentType<Omit<TOwnProps, 'styles' | 'makeStyles'>>

type GenerateStyle<TStyles, TOwnProps> = (
  theme: ComponentTheme,
  props: TOwnProps,
  extraArgs?: Record<string, unknown>
) => TStyles
type GenerateComponentTheme = (theme: BaseTheme) => ComponentTheme

type WithStyle = <TStyles, TOwnProps>(
  gs: GenerateStyle<TStyles, TOwnProps>,
  gct: GenerateComponentTheme
) => WithStyleHOC<TStyles>

/**
 * Wrapper for original inst-ui withStyle HOC
 * Makes it possible to preserve wrapped component properties typings
 * @param gs generateStyle function
 * @param gct generateComponentTheme function
 * @returns Wrapped component with the same properties types as
 * original component but without 'styles' prop as it is being provided
 * with the HOC
 * @example app/javascript/mc-app/assesments/Sections/Section/index.tsx
 */
export const withStyle: WithStyle = (gs, gct) => (Component) =>
  instUiWithStyle(gs, gct)(Component as ComponentClass)

/**
 * Extracts styles prop from withStyle result so it can be provided to
 * wrapped component type
 * @example app/javascript/mc-app/assesments/Sections/Section/index.tsx
 */
export type InjectedProps<THOC> = THOC extends WithStyleHOC<infer TStyles>
  ? {
      styles: TStyles
      makeStyles: (extraArgs?: Record<string, unknown>) => void
    }
  : never
