import { Controller, FieldError, useFormContext } from 'react-hook-form'
import { SimpleSelect, Text, Spinner, Flex } from '@instructure/ui'
import type { FormMessage } from '@instructure/ui-form-field'
import { selectorAttrs } from '../../../../../mc-ui/utils/selectorAttrs'
import { PathwaysData } from '../../../../../mc-app/curriculum-map/types'
import {
  defaultValue,
  pathwayInputName,
  classInputName,
} from '../../../helpers/constants'
import { useCoreOptionsData } from '../../../hooks/useCoreOptionsData'
import { RequiredLabel } from '../../RequiredLabel'
import { useStateId } from '../../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'
import { useIsRestrictedByDistrict } from 'mc-app/curriculum-map/hooks/useIsRestrictedByDistrict'
import { DistrictApprovedBadge } from './DistrictApprovedBadge'

interface Props {
  pathwayId: string
  subjectId: string
  showAllPathways: boolean
  setShowAllPathways: (showAllPathways: boolean) => void
}

export const PathwaySelector = ({
  pathwayId,
  subjectId,
  showAllPathways,
  setShowAllPathways,
}: Props) => {
  const {
    formState: { errors },
    resetField,
  } = useFormContext()

  const stateId = useStateId()

  const { coreOptionsData, isGetCoreOptionsDataFetching } = useCoreOptionsData(
    subjectId,
    String(stateId)
  )

  const { all, relevant }: PathwaysData = coreOptionsData
    ? coreOptionsData
    : { all: {}, relevant: {} }

  const isRestrictedByDistrict = useIsRestrictedByDistrict()

  const allPathways = all
  const pathways = !parseInt(subjectId) ? {} : relevant

  const pathwayOptions = showAllPathways ? allPathways : pathways

  const isLoading = subjectId !== defaultValue && isGetCoreOptionsDataFetching
  const isDisabled = subjectId === defaultValue || isGetCoreOptionsDataFetching

  const handlePathwayChange = (event: React.SyntheticEvent, data) => {
    const selectedPathwayId = data.value as string
    if (selectedPathwayId === 'show_all') {
      setShowAllPathways(true)
    } else if (selectedPathwayId === 'show_relevant') {
      setShowAllPathways(false)
    }
    resetField(classInputName)
  }

  function formatErrorMessage(errorMessage: string): FormMessage[] {
    return [
      {
        text: (<Text>{errorMessage}</Text>) as React.ReactNode,
        type: 'error',
      },
    ]
  }

  function handleErrorMessages(error: FieldError): FormMessage[] {
    if (error?.type === 'minLength' || error?.type === 'validate') {
      const message = 'Core cannot be blank'
      return formatErrorMessage(message)
    }
    return []
  }

  return (
    <Controller
      name={pathwayInputName}
      defaultValue={pathwayId}
      rules={{
        minLength: 1,
        required: true,
        validate: (value) => value !== defaultValue,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <SimpleSelect
          renderLabel={
            <Flex alignItems="center" gap="xxx-small">
              <DistrictApprovedBadge
                isDistrictApproved={isRestrictedByDistrict}
              />
              {RequiredLabel('Core')}
            </Flex>
          }
          id={pathwayInputName}
          value={value}
          onChange={(event: React.SyntheticEvent, data) => {
            onChange(data.value as string)
            handlePathwayChange(event, data)
          }}
          onBlur={onBlur}
          inputRef={ref}
          messages={handleErrorMessages(errors[pathwayInputName] as FieldError)}
          {...selectorAttrs('cmap-core-input')}
          disabled={isDisabled}
          renderBeforeInput={
            isLoading ? <Spinner renderTitle="Loading" size="x-small" /> : null
          }
        >
          <SimpleSelect.Option
            id={defaultValue}
            value={defaultValue}
            {...selectorAttrs('core-input-default-option')}
          >
            Select Core
          </SimpleSelect.Option>
          {Object.keys(pathwayOptions || []).map((pathwayGroup) => {
            return (
              <SimpleSelect.Group
                renderLabel={pathwayGroup.toUpperCase()}
                key={pathwayGroup}
              >
                {pathwayOptions[pathwayGroup].map(({ name, id }) => {
                  const strId = id?.toString()

                  return (
                    <SimpleSelect.Option
                      id={`${strId}-${pathwayGroup}`}
                      key={`${strId}-${pathwayGroup}`}
                      value={strId}
                      {...selectorAttrs(
                        `core-input-${strId}-${pathwayGroup}-option`
                      )}
                    >
                      {name}
                    </SimpleSelect.Option>
                  )
                })}
              </SimpleSelect.Group>
            )
          })}
          {showAllPathways && subjectId !== defaultValue ? (
            <SimpleSelect.Option
              id="show_relevant"
              value="show_relevant"
              {...selectorAttrs('core-input-show-relevant-option')}
            >
              - Show only relevant cores -
            </SimpleSelect.Option>
          ) : (
            subjectId !== defaultValue && (
              <SimpleSelect.Option
                id="show_all"
                value="show_all"
                {...selectorAttrs('core-input-show-all-option')}
              >
                - Show all cores -
              </SimpleSelect.Option>
            )
          )}
        </SimpleSelect>
      )}
    />
  )
}
