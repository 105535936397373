import { useQuery } from '@tanstack/react-query'
import { Alignments } from '../../../mc-resources/internal/Alignments'
import { PathwaysData } from '../types'
import { defaultValue } from '../helpers/constants'

interface ReturnedPathways {
  [key: string]: (string | number)[][]
}
interface ReturnedData {
  all?: ReturnedPathways
  relevant?: ReturnedPathways
}

export const useCoreOptionsData = (subjectId: string, stateId: string) => {
  const convertInnerArraysToObject = (data: ReturnedData): PathwaysData => {
    const convertedData = {}
    Object.keys(data).forEach((pathway) => {
      convertedData[pathway] = {}
      Object.keys(data[pathway]).forEach((key) => {
        convertedData[pathway][key] = data[pathway][key].map(([name, id]) => ({
          id,
          name,
        }))
      })
    })

    return convertedData
  }

  const { data: coreOptionsData, isFetching: isGetCoreOptionsDataFetching } =
    useQuery({
      queryKey: ['core_options', subjectId, stateId],
      queryFn: async () => {
        const aligmentsResource = Alignments.createInstance({
          subjectID: subjectId,
          stateID: stateId,
        })
        const result = await aligmentsResource.statePathwaySets()
        return convertInnerArraysToObject(result.pathways as ReturnedData)
      },
      enabled: subjectId && subjectId !== defaultValue,
    })

  return {
    coreOptionsData,
    isGetCoreOptionsDataFetching,
  }
}
