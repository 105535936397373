import { Global } from '@instructure/emotion'
import type { Theme } from '@instructure/ui-themes'
import { InjectedProps, withStyle } from '../../hoc'

const generateComponentTheme = (theme: Theme) => ({
  borderColor: theme.colors?.borderMedium,
  borderColoDivider: theme.colors?.borderDarkest,
})

const generateStyle = (
  componentTheme: ReturnType<typeof generateComponentTheme>
) => componentTheme

const withStyleHoc = withStyle(generateStyle, generateComponentTheme)
type StylesProps = InjectedProps<typeof withStyleHoc>
const TableCellBorderStyles = withStyleHoc(({ styles }: StylesProps) => {
  return (
    <Global
      styles={{
        'table[data-styles-basic-table="true"] td[data-styles-border-bottom="true"]':
          {
            border: 'inherit',
            borderColor: styles.borderColor,
          },
        'table[data-styles-basic-table="true"]  tr:has(td[data-styles-border-bottom="true"])':
          {
            borderColor: 'transparent',
          },
        'table[data-styles-basic-table="true"] td[data-styles-border-bottom-divider="true"]':
          {
            border: 'inherit',
            borderColor: styles.borderColoDivider,
          },
      }}
    />
  )
})

export { TableCellBorderStyles }
