import { Controller, FieldError, useFormContext } from 'react-hook-form'
import {
  defaultValue,
  scaleInputName,
} from '../../../../mc-app/curriculum-map/helpers/constants'
import { SimpleSelect, Text } from '@instructure/ui'
import type { FormMessage } from '@instructure/ui-form-field'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import {
  useDefaultScaleId,
  useScales,
} from '../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

export const ScaleSelector = () => {
  const scales = useScales()
  const scaleId = useDefaultScaleId()
  const {
    formState: { errors },
  } = useFormContext()

  function formatErrorMessage(errorMessage: string): FormMessage[] {
    return [
      {
        text: (<Text>{errorMessage}</Text>) as React.ReactNode,
        type: 'error',
      },
    ]
  }

  function handleErrorMessages(error: FieldError): FormMessage[] {
    if (error?.type === 'minLength' || error?.type === 'validate') {
      const message = 'Subject cannot be blank'
      return formatErrorMessage(message)
    }
    return []
  }

  return (
    <Controller
      name={scaleInputName}
      defaultValue={scaleId}
      rules={{
        validate: (value) => value !== defaultValue,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <SimpleSelect
          renderLabel="Mastery Scale"
          id={scaleInputName}
          messages={handleErrorMessages(errors[scaleInputName] as FieldError)}
          {...selectorAttrs('cmap-scale-input')}
          value={value}
          onBlur={onBlur}
          inputRef={ref}
          onChange={(event: React.SyntheticEvent, data) => {
            onChange(data.value as string)
          }}
        >
          <SimpleSelect.Option
            id={defaultValue}
            value={defaultValue}
            {...selectorAttrs('cmap-scale-input-default-option')}
          >
            Select Scale
          </SimpleSelect.Option>
          {scales.map((scale) => (
            <SimpleSelect.Option
              id={`${scale.id}`}
              key={`${scale.id}`}
              value={`${scale.id}`}
              {...selectorAttrs(`cmap-scale-input-${scale.id}-option`)}
            >
              {`${scale.name}`}
            </SimpleSelect.Option>
          ))}
        </SimpleSelect>
      )}
    />
  )
}
