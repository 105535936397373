import {
  Button,
  CloseButton,
  Modal,
  Flex,
  Text,
  Spinner,
} from '@instructure/ui'
import { FormProvider, useForm } from 'react-hook-form'
import { CurriculumMapNewForm } from '../CurriculumMapNewForm'
import { useState } from 'react'
import { CurriculumMapNewFormData } from '../../../../mc-app/curriculum-map/types'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import {
  CreateFromClassroomRequestBody,
  CurriculumMapCreateRequestBody,
} from '../../../../mc-resources/curriculum-map'
import { useCreateCmap } from '../../../../mc-app/curriculum-map/hooks/useCreateCmap'
import {
  useCore,
  useGrade,
  useSubject,
  useClassroomId,
  useMaterial,
  useScaleId,
} from '../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'
import { useCreateCmapFromTracker } from '../../../../mc-app/curriculum-map/hooks/useCreateCmapFromTracker'

interface Props {
  newModalOpen: boolean
  setNewModalOpen: (open: boolean) => void
}

export const NewModal = ({ newModalOpen, setNewModalOpen }: Props) => {
  const methods = useForm()
  const createCmap = useCreateCmap()
  const createCmapFromTracker = useCreateCmapFromTracker()
  const subject = useSubject()
  const core = useCore()
  const grade = useGrade()
  const classroomId = useClassroomId()
  const passedScaleId = useScaleId()
  const materialId = useMaterial()?.id

  const [isSaving, setIsSaving] = useState(false)

  const handleModalDismiss = () => {
    setNewModalOpen(false)
  }

  const formatRequest = ({
    title,
    description,
    subjectId,
    pathwayId,
    classId,
    ownerId,
    privacyId,
    districtApproved,
    scaleId,
  }: CurriculumMapNewFormData): CurriculumMapCreateRequestBody => {
    const request = {
      curriculumMap: {
        title: title,
        description: description,
        subjectId: subject ? subject.id : subjectId,
        pathwayId: core ? core.id : pathwayId,
        classObjectiveId: grade ? grade.id : classId,
        collectionId: privacyId,
        restrictCloning: districtApproved,
      },
      ownerId: ownerId,
    }
    if (passedScaleId) request.curriculumMap['scaleId'] = passedScaleId
    else if (scaleId) request.curriculumMap['scaleId'] = scaleId
    if (classroomId) request['classroomId'] = classroomId
    if (materialId) request['materialId'] = materialId
    return request
  }

  const formatFromTrackerRequest = (
    data: CurriculumMapNewFormData
  ): CreateFromClassroomRequestBody => {
    const { title, description, ownerId, privacyId, districtApproved } = data
    const request = {
      curriculumMap: {
        title: title,
        description: description,
        collectionId: privacyId,
        restrictCloning: districtApproved,
        scaleId: passedScaleId,
      },
      ownerId: ownerId,
      classroomId: classroomId,
    }
    return request
  }

  const handleSuccess = (curriculumMapId: number) => {
    window.location.href = `/curriculum_maps/${curriculumMapId}`
  }

  const handleError = (error) => {
    console.error(error)
    setIsSaving(false)
  }

  const handleFormSubmit = (data: CurriculumMapNewFormData) => {
    setIsSaving(true)
    if (classroomId) {
      createCmapFromTracker.mutate(formatFromTrackerRequest(data), {
        onError: (error) => handleError(error),
        onSuccess: ({ curriculumMapId }) => handleSuccess(curriculumMapId),
      })
    } else {
      createCmap.mutate(formatRequest(data), {
        onError: (error) => handleError(error),
        onSuccess: ({ curriculumMapId }) => handleSuccess(curriculumMapId),
      })
    }
  }

  const submitButtonInteraction = () => {
    const {
      formState: { isValid },
    } = methods
    return isValid ? 'enabled' : 'disabled'
  }

  const spinner = (
    <Spinner
      renderTitle="Saving"
      size="x-small"
      variant="inverse"
      themeOverride={(_, { colors }) => ({
        inverseColor: colors.white,
      })}
    />
  )

  const savingButton = (
    <Button
      type="button"
      color="primary"
      margin="xx-small"
      interaction={'disabled'}
      {...selectorAttrs('new-cmap-modal-saving-button')}
      renderIcon={spinner}
    >
      Saving
    </Button>
  )

  const createButton = (
    <Button
      type="submit"
      color="primary"
      margin="xx-small"
      interaction={submitButtonInteraction()}
      onClick={methods.handleSubmit(handleFormSubmit)}
      {...selectorAttrs('new-cmap-modal-create-button')}
    >
      Create
    </Button>
  )

  return (
    <FormProvider {...methods}>
      <Modal
        open={newModalOpen}
        label="New Curriculum Map"
        onExited={() => methods.reset()}
      >
        <Modal.Header>
          <Flex alignItems="center" justifyItems="space-between">
            <Text size="x-large">New Curriculum Map</Text>
            <CloseButton
              onClick={handleModalDismiss}
              screenReaderLabel="Close"
              size="medium"
              {...selectorAttrs('new-cmap-modal-close-button')}
            />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <CurriculumMapNewForm />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="secondary"
            margin="xx-small"
            onClick={handleModalDismiss}
            {...selectorAttrs('new-cmap-modal-cancel-button')}
          >
            Cancel
          </Button>
          {isSaving ? savingButton : createButton}
        </Modal.Footer>
      </Modal>
    </FormProvider>
  )
}
