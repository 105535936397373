import { TextInput, Text } from '@instructure/ui'
import { useFormContext, Controller, FieldError } from 'react-hook-form'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import type { FormMessage } from '@instructure/ui-form-field'
import { RequiredLabel } from '../RequiredLabel'
import { useCurriculumMap } from '../../contexts/CmapTrackerContext'

const INPUT_NAME = 'title'

const TitleInput = () => {
  const title = useCurriculumMap()?.name || ''
  const {
    formState: { errors },
  } = useFormContext()

  function formatErrorMessage(errorMessage: string): FormMessage[] {
    return [
      {
        text: (<Text>{errorMessage}</Text>) as React.ReactNode,
        type: 'error',
      },
    ]
  }

  function handleErrorMessages(error: FieldError): FormMessage[] {
    if (error?.type === 'minLength' || error?.type === 'required') {
      const message = 'Map name cannot be blank'
      return formatErrorMessage(message)
    }
    return []
  }

  return (
    <Controller
      name={INPUT_NAME}
      defaultValue={title}
      rules={{
        minLength: 1,
        required: true,
      }}
      render={({ field }) => (
        <TextInput
          renderLabel={RequiredLabel('Name')}
          name={INPUT_NAME}
          id="title"
          shouldNotWrap
          isRequired={true}
          messages={handleErrorMessages(errors[INPUT_NAME] as FieldError)}
          {...selectorAttrs('input-title-cmap-edit-form')}
          {...field}
        />
      )}
    />
  )
}

export default TitleInput
