import { useEffect, useMemo, useState } from 'react'

export const useTablePagination = <T>(
  items: T[],
  pageSize = 11
): {
  pageItems: T[]
  pagesCount: number
  currentPage: number
  onCurrentPageChange: (currentPage: number) => void
} => {
  const [currentPage, setCurrentPage] = useState(0)
  const pagesCount = Math.ceil(items.length / pageSize)

  useEffect(() => {
    // protect from incorrect page
    if (currentPage >= pagesCount && currentPage > 0) {
      setCurrentPage(pagesCount - 1)
    }
  }, [currentPage, pagesCount])

  const pageItems = useMemo(() => {
    const start = currentPage * pageSize
    const end = start + pageSize
    return items.slice(start, end)
  }, [items, currentPage, pageSize])

  return {
    pageItems,
    pagesCount,
    currentPage,
    onCurrentPageChange: setCurrentPage,
  }
}
