import { useMutation } from '@tanstack/react-query'
import {
  CreateFromClassroomRequestBody,
  CreateFromClassroomResource,
} from 'mc-resources/curriculum-map'

const curriculumMapResource = CreateFromClassroomResource.createInstance()

export const useCreateCmapFromTracker = () => {
  return useMutation({
    mutationFn: (data: CreateFromClassroomRequestBody) =>
      curriculumMapResource.create(data),
    mutationKey: ['createCmapFromTracker'],
  })
}
