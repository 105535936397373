import { useQuery } from '@tanstack/react-query'
import { Alignments } from '../../../mc-resources/internal/Alignments'
import { camelizeKeys } from '../../../mc-sdk/case-utils'
import { defaultValue } from '../helpers/constants'

export const useClassObjectivesData = (
  pathwayId: string,
  subjectId: string
) => {
  const {
    data: classObjectivesData,
    isFetching: isGetClassObjectivesDataFetching,
  } = useQuery({
    queryKey: ['class_objectives', pathwayId, subjectId],
    queryFn: async () => {
      const aligmentsResource = Alignments.createInstance({
        subjectID: subjectId,
        pathwayID: pathwayId,
      })
      const result = await aligmentsResource.classObjectives()
      return camelizeKeys(result)
    },
    enabled:
      pathwayId &&
      pathwayId !== defaultValue &&
      subjectId &&
      subjectId !== defaultValue,
  })

  return {
    classObjectivesData,
    isGetClassObjectivesDataFetching,
  }
}
