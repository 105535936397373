import { CurriculumMap, PrivacyOption, Scale, Subject, Team } from '../types'
import {
  AlignmentField,
  Tracker,
  SisOption,
  Teacher,
  Section,
} from '../../classrooms/types'
import { Material } from '../../../mc-resources/materials'
import { createContext, ReactNode, useContext, useState } from 'react'

export type CmapTrackerContextValues = {
  curriculumMap?: CurriculumMap
  setCurriculumMap?: (curriculumMap: CurriculumMap) => void
  selectedPrivacy?: number
  setSelectedPrivacy?: (privacyId: number) => void
  privacyOptions?: PrivacyOption[]
  setPrivacyOptions?: (privacyOptions: PrivacyOption[]) => void
  currentTeacher?: Teacher
  activeTeams?: Team[]
  isNetwork?: boolean
  hasOrIsNetwork?: boolean
  showDistrictApproved?: boolean
  enableDistrictApproved?: boolean
  grade?: AlignmentField
  setGrade?: (grade: AlignmentField) => void
  subject?: AlignmentField
  setSubject?: (subject: AlignmentField) => void
  core?: AlignmentField
  setCore?: (core: AlignmentField) => void
  subjects?: Subject[]
  stateId?: number
  mcClasses?: Tracker[]
  bsClasses?: Tracker[]
  showSisOption?: boolean
  sisOptions?: SisOption[]
  scales?: Scale[]
  hasDistrictRoles?: boolean
  classroom?: Tracker
  sections?: Section[]
  teacherTeams?: Teacher[]
  defaultScaleId?: string
  scaleId?: string | number
  classroomId?: number
  showCMapForCollab?: boolean
  material?: Material
  hasForeignStandards?: boolean
  allowedToCreateTrackers?: boolean
  permissions?: {
    canClone: boolean
    canDeliver: boolean
    canRevertToDraft: boolean
  }
  reassessBankIds?: number[]
}

const CmapTrackerContext = createContext<CmapTrackerContextValues | undefined>(
  undefined
)

const CmapTrackerContextProvider = ({
  children,
  cmapTrackerContext,
}: {
  children: ReactNode
  cmapTrackerContext: CmapTrackerContextValues
}) => {
  const [grade, setGrade] = useState(cmapTrackerContext.grade)
  const [subject, setSubject] = useState(cmapTrackerContext.subject)
  const [core, setCore] = useState(cmapTrackerContext.core)
  const [curriculumMap, setCurriculumMap] = useState(
    cmapTrackerContext.curriculumMap
  )
  const [privacyOptions, setPrivacyOptions] = useState(
    cmapTrackerContext.privacyOptions
  )
  const [selectedPrivacy, setSelectedPrivacy] = useState(
    cmapTrackerContext.selectedPrivacy
  )
  const context = { ...cmapTrackerContext }
  context.grade = grade
  context.setGrade = setGrade
  context.subject = subject
  context.setSubject = setSubject
  context.core = core
  context.setCore = setCore
  context.curriculumMap = curriculumMap
  context.setCurriculumMap = setCurriculumMap
  context.privacyOptions = privacyOptions
  context.setPrivacyOptions = setPrivacyOptions
  context.selectedPrivacy = selectedPrivacy
  context.setSelectedPrivacy = setSelectedPrivacy

  return (
    <CmapTrackerContext.Provider value={context}>
      {children}
    </CmapTrackerContext.Provider>
  )
}

const useCmapTrackerContext = () => {
  return useContext(CmapTrackerContext)
}

const useCurriculumMap = () => {
  const { curriculumMap } = useCmapTrackerContext()
  return curriculumMap
}

const useSelectedPrivacy = () => {
  const { selectedPrivacy } = useCmapTrackerContext()
  return selectedPrivacy
}

const usePrivacyOptions = () => {
  const { privacyOptions } = useCmapTrackerContext()
  return privacyOptions
}

const useCurrentTeacher = () => {
  const { currentTeacher } = useCmapTrackerContext()
  return currentTeacher
}

const useActiveTeams = () => {
  const { activeTeams } = useCmapTrackerContext()
  return activeTeams
}

const useIsNetwork = () => {
  const { isNetwork } = useCmapTrackerContext()
  return isNetwork
}

const useHasOrIsNetwork = () => {
  const { hasOrIsNetwork } = useCmapTrackerContext()
  return hasOrIsNetwork
}

const useShowDistrictApproved = () => {
  const { showDistrictApproved } = useCmapTrackerContext()
  return showDistrictApproved
}

const useEnableDistrictApproved = () => {
  const { enableDistrictApproved } = useCmapTrackerContext()
  return enableDistrictApproved
}

const useGrade = () => {
  const { grade } = useCmapTrackerContext()
  return grade
}

const useSubject = () => {
  const { subject } = useCmapTrackerContext()
  return subject
}

const useCore = () => {
  const { core } = useCmapTrackerContext()
  return core
}

const useSubjects = () => {
  const { subjects } = useCmapTrackerContext()
  return subjects
}

const useStateId = () => {
  const { stateId } = useCmapTrackerContext()
  return stateId
}

const useMcClasses = () => {
  const { mcClasses } = useCmapTrackerContext()
  return mcClasses
}

const useBsClasses = () => {
  const { bsClasses } = useCmapTrackerContext()
  return bsClasses
}

const useShowSisOption = () => {
  const { showSisOption } = useCmapTrackerContext()
  return showSisOption
}

const useSisOptions = () => {
  const { sisOptions } = useCmapTrackerContext()
  return sisOptions
}

const useScales = () => {
  const { scales } = useCmapTrackerContext()
  return scales
}

const useHasDistrictRoles = () => {
  const { hasDistrictRoles } = useCmapTrackerContext()
  return hasDistrictRoles
}

const useClassroom = () => {
  const { classroom } = useCmapTrackerContext()
  return classroom
}

const useSections = () => {
  const { sections } = useCmapTrackerContext()
  return sections
}

const useTeacherTeams = () => {
  const { teacherTeams } = useCmapTrackerContext()
  return teacherTeams
}

const useSetGrade = () => {
  const { setGrade } = useCmapTrackerContext()
  return setGrade
}

const useSetSubject = () => {
  const { setSubject } = useCmapTrackerContext()
  return setSubject
}

const useSetCore = () => {
  const { setCore } = useCmapTrackerContext()
  return setCore
}

const useSetCurriculumMap = () => {
  const { setCurriculumMap } = useCmapTrackerContext()
  return setCurriculumMap
}

const useDefaultScaleId = () => {
  const { defaultScaleId } = useCmapTrackerContext()
  return defaultScaleId
}

const useSetPrivacyOptions = () => {
  const { setPrivacyOptions } = useCmapTrackerContext()
  return setPrivacyOptions
}

const useSetSelectedPrivacy = () => {
  const { setSelectedPrivacy } = useCmapTrackerContext()
  return setSelectedPrivacy
}

const useScaleId = () => {
  const { scaleId } = useCmapTrackerContext()
  return scaleId
}

const useClassroomId = () => {
  const { classroomId } = useCmapTrackerContext()
  return classroomId
}

const useShowCMapForCollab = () => {
  const { showCMapForCollab } = useCmapTrackerContext()
  return showCMapForCollab
}

const useMaterial = () => {
  const { material } = useCmapTrackerContext()
  return material
}

const useHasForeignStandards = () => {
  const { hasForeignStandards } = useCmapTrackerContext()
  return hasForeignStandards
}

const usePermissions = () => {
  const { permissions } = useCmapTrackerContext()
  return permissions
}

const useReassessBankIds = () => {
  const { reassessBankIds } = useCmapTrackerContext()
  return reassessBankIds
}

export {
  CmapTrackerContextProvider,
  useCurriculumMap,
  useSetCurriculumMap,
  useSelectedPrivacy,
  usePrivacyOptions,
  useSetPrivacyOptions,
  useCurrentTeacher,
  useActiveTeams,
  useIsNetwork,
  useHasOrIsNetwork,
  useShowDistrictApproved,
  useEnableDistrictApproved,
  useGrade,
  useSubject,
  useCore,
  useSubjects,
  useStateId,
  useMcClasses,
  useBsClasses,
  useShowSisOption,
  useSisOptions,
  useScales,
  useHasDistrictRoles,
  useClassroom,
  useSections,
  useTeacherTeams,
  useSetGrade,
  useSetSubject,
  useSetCore,
  useDefaultScaleId,
  useSetSelectedPrivacy,
  useScaleId,
  useClassroomId,
  useShowCMapForCollab,
  useMaterial,
  useHasForeignStandards,
  usePermissions,
  useReassessBankIds,
}
