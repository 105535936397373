import { SimpleSelect, View } from '@instructure/ui'
import { Controller } from 'react-hook-form'
import { PrivacyLevel } from '../../../../mc-app/curriculum-map/helpers/constants'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import {
  usePrivacyOptions,
  useSelectedPrivacy,
  useSetSelectedPrivacy,
} from '../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

const INPUT_NAME = 'privacyId'

export const PrivacySelector = () => {
  const filteredPrivacyOptions = usePrivacyOptions()
  const privacyId = useSelectedPrivacy()
  const setPrivacyId = useSetSelectedPrivacy()

  const DEFAULT_PRIVACY_OPTION = PrivacyLevel.PUBLIC
  const includesPrivacyId = filteredPrivacyOptions.some(
    ({ privacyLevel }) => privacyLevel === privacyId
  )
  if (!includesPrivacyId) setPrivacyId(DEFAULT_PRIVACY_OPTION)

  return (
    <View as={'div'} margin="0 0 medium" width={'100%'}>
      <Controller
        name={INPUT_NAME}
        defaultValue={String(privacyId)}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <SimpleSelect
            renderLabel="Privacy"
            id="curriculum_map_collection_id"
            value={value}
            inputRef={ref}
            onBlur={onBlur}
            onChange={(event: React.SyntheticEvent, data) =>
              onChange(data.value as string)
            }
            {...selectorAttrs('input-privacy-cmap-form')}
          >
            {filteredPrivacyOptions.map(({ privacyLabel, privacyLevel }) => (
              <SimpleSelect.Option
                key={privacyLevel}
                id={String(privacyLevel)}
                value={String(privacyLevel)}
              >
                {String(privacyLabel)}
              </SimpleSelect.Option>
            ))}
          </SimpleSelect>
        )}
      />
    </View>
  )
}
