import { Flex, IconTargetLine, Text, View } from '@instructure/ui'
import {
  useCore,
  useGrade,
  useShowCMapForCollab,
  useSubject,
} from '../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

export const SelectedAlignments = () => {
  const subject = useSubject()
  const core = useCore()
  const grade = useGrade()
  const showCMapForCollab = useShowCMapForCollab()

  return (
    <View as={'div'} margin="0 0 medium" width={'100%'}>
      {showCMapForCollab ? (
        <Flex alignItems="center" margin="0 0 x-small">
          <IconTargetLine size="x-small" />
          <Flex.Item margin="0 0 0 xx-small">
            <Text weight="bold">C-map for Collab</Text>
          </Flex.Item>
        </Flex>
      ) : null}
      <View
        background="secondary"
        as="div"
        width={'100%'}
        borderRadius="medium"
      >
        <Flex as={'div'} direction="column" alignItems="start" padding="small">
          <Flex.Item margin="0 0 xx-small">
            <Text>{subject.name}</Text>
          </Flex.Item>
          <Flex.Item margin="0 0 xx-small">
            <Text>{core.name}</Text>
          </Flex.Item>
          <Text>{grade.name}</Text>
        </Flex>
      </View>
    </View>
  )
}
