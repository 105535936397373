import { useState } from 'react'
import { View } from '@instructure/ui'
import { useFormContext } from 'react-hook-form'
import { SubjectSelector } from './AlignmentFields/SubjectSelector'
import { PathwaySelector } from './AlignmentFields/PathwaySelector'
import { ClassSelector } from './AlignmentFields/ClassSelector'
import {
  defaultValue,
  subjectInputName,
  pathwayInputName,
  classInputName,
} from '../../../../mc-app/curriculum-map/helpers/constants'

export const AlignmentFieldsSelector = () => {
  const { watch } = useFormContext()

  const [showAllPathways, setShowAllPathways] = useState(false)
  const subjectId = watch(subjectInputName) || defaultValue
  const pathwayId = watch(pathwayInputName) || defaultValue
  const classObjectiveId = watch(classInputName) || defaultValue

  return (
    <>
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <SubjectSelector
          subjectId={subjectId}
          setShowAllPathways={setShowAllPathways}
        />
      </View>
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <PathwaySelector
          pathwayId={pathwayId}
          showAllPathways={showAllPathways}
          subjectId={subjectId}
          setShowAllPathways={setShowAllPathways}
        />
      </View>
      <View margin="0 0 medium" width={'100%'}>
        <ClassSelector
          classObjectiveId={classObjectiveId}
          pathwayId={pathwayId}
          subjectId={subjectId}
        />
      </View>
    </>
  )
}
