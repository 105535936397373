import { BasicTableProps } from './types'
import { Table } from '@instructure/ui'
import { Head } from './Head'
import { Row } from './Row'
import { TableCellBorderStyles } from './styles'
import { useCallback, useRef } from 'react'

export const BasicTable = <T extends { id: number }>({
  items,
  columns,
  caption,
  sort,
  onSortChange,
  rowAttributes,
  layout,
  headRowAttributes: propsHeadRowAttributes,
}: BasicTableProps<T>) => {
  const headRowAttributesRef = useRef(propsHeadRowAttributes)
  headRowAttributesRef.current = propsHeadRowAttributes

  const headRowAttributes = useCallback(
    () => headRowAttributesRef.current?.(),
    []
  )
  return (
    <>
      <TableCellBorderStyles />
      <Table caption={caption} layout={layout} data-styles-basic-table={true}>
        <Head
          columns={columns}
          sort={sort}
          onSortChange={onSortChange}
          rowAttributes={headRowAttributes}
        />
        <Table.Body>
          {items.map((item) => (
            <Row
              key={item.id}
              item={item}
              columns={columns}
              rowAttributes={rowAttributes}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
