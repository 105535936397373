import { IconApprovedStar } from '../../../../mc-icons/svg'
import { Checkbox, Flex, Text, View } from '@instructure/ui'
import { Controller } from 'react-hook-form'
import { InfoPopover } from '../../../../mc-ui/elements/InfoPopover'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'
import {
  useCurriculumMap,
  useEnableDistrictApproved,
} from '../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'

const INPUT_NAME = 'districtApproved'

const DistrictApprovedSwitch = () => {
  const initialValue = useCurriculumMap()?.restrictCloning
  const enableDistrictApproved = useEnableDistrictApproved()

  return (
    <Controller
      name={INPUT_NAME}
      defaultValue={initialValue}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Checkbox
          label={
            <Flex alignItems={'center'}>
              <Text weight="bold">District Approved</Text>
              <View margin={'0 0 0 small'} display={'flex'}>
                <InfoPopover
                  renderTrigger={
                    <Flex>
                      <IconApprovedStar color={'alert'} />
                    </Flex>
                  }
                  screenReaderLabel={'District Approved Content'}
                >
                  <InfoPopover.Title>
                    District Approved Content
                  </InfoPopover.Title>
                  <InfoPopover.Content>
                    Only users with content approval permissions can edit or
                    clone district approved assessments.
                  </InfoPopover.Content>
                </InfoPopover>
              </View>
            </Flex>
          }
          {...selectorAttrs('input-district-approved-cmap-form')}
          labelPlacement="start"
          variant={'toggle'}
          checked={value}
          onChange={onChange}
          disabled={!enableDistrictApproved}
          onBlur={onBlur}
          ref={ref}
        />
      )}
    />
  )
}

export { DistrictApprovedSwitch }
