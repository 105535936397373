import { useMutation } from '@tanstack/react-query'

import {
  CurriculumMapResource,
  CurriculumMapCreateRequestBody,
} from '../../../mc-resources/curriculum-map'

const curriculumMapResource = CurriculumMapResource.createInstance()

export const useCreateCmap = () => {
  return useMutation({
    mutationFn: (data: CurriculumMapCreateRequestBody) =>
      curriculumMapResource.create(data),
    mutationKey: ['createCmap'],
  })
}
