import { useMemo, useState } from 'react'
import { Sort } from '../Head'
import { ColumnConfig } from '../types'

export const useTableSort = <T>(
  items: T[],
  columns: ColumnConfig<T>[]
): {
  sortedItems: T[]
  sort: Sort
  onSortChange: (sort: Sort) => void
} => {
  const [sort, setSort] = useState({} as Sort)

  const sortedItems = useMemo(() => {
    const column = columns.find(({ id }) => id === sort?.by)
    return column?.compare
      ? [...items].sort(column.compare(sort.ascending))
      : items
  }, [items, sort, columns])

  return {
    sortedItems,
    sort,
    onSortChange: setSort,
  }
}
