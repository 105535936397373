export const breakpoints = {
  small: 0,
  medium: 768,
  large: 992,
}

type Direction = 'above' | 'below'

/**
 * Function to generate a media query string.
 *
 * @param {keyof typeof breakpoints} breakpoint - The breakpoint at which the media query should apply.
 * @param {Direction} direction - The direction of the media query. Defaults to 'above'.
 *
 * The direction parameter can be either 'above' or 'below':
 * - 'above': Generates a media query for styles that should apply to screens with a width greater than the specified breakpoint.
 *   This corresponds to the 'min-width' property in CSS. For example, if the breakpoint is 'medium' (768px), `mq('medium', 'above')`
 *   will generate a media query that applies to screens with a width of 768px or more.
 * - 'below': Generates a media query for styles that should apply to screens with a width less than the specified breakpoint.
 *   This corresponds to the 'max-width' property in CSS. For example, if the breakpoint is 'medium' (768px), `mq('medium', 'below')`
 *   will generate a media query that applies to screens with a width of less than 768px.
 *
 * @returns {string} A string for CSS @media rule.
 */
export const mq = (
  breakpoint: keyof typeof breakpoints,
  direction: Direction = 'above'
): string => {
  const cssDirection = direction === 'above' ? 'min' : 'max'
  return `@media (${cssDirection}-width: ${breakpoints[breakpoint]}px)`
}

const breakpointsArray = Object.entries(breakpoints).sort((a, b) => a[1] - b[1])

/**
 * Function to generate a media query string for a range of screen sizes.
 *
 * @param {keyof typeof breakpoints} breakpoint - The breakpoint that defines the range.
 *
 * For example, if the breakpoints are { small: 0, medium: 768, large: 992 }:
 * - `mqRange('small')` will generate a media query that applies to screens with a width of 0px to 767px.
 * - `mqRange('medium')` will generate a media query that applies to screens with a width of 768px to 991px.
 * - `mqRange('large')` will generate a media query that applies to screens with a width of 992px or more.
 *
 * @returns {string} A string for CSS @media rule.
 */
export const mqRange = (breakpoint: keyof typeof breakpoints): string => {
  const index = breakpointsArray.findIndex(([key]) => key === breakpoint)
  const min = breakpoints[breakpoint]
  const max =
    index < breakpointsArray.length - 1
      ? breakpointsArray[index + 1][1] - 1
      : undefined

  if (min >= 0 && max !== undefined) {
    return `@media (min-width: ${min}px) and (max-width: ${max}px)`
  } else {
    return `@media (min-width: ${min}px)`
  }
}
