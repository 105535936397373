export const compareStrings =
  (field: string) => (ascending: boolean) => (a, b) => {
    const upperA = a[field]?.toUpperCase() ?? ''
    const upperB = b[field]?.toUpperCase() ?? ''

    if (upperA < upperB) return ascending ? -1 : 1
    if (upperA > upperB) return ascending ? 1 : -1
    return 0
  }

export const compareNumbers =
  (field: string) => (ascending: boolean) => (a, b) => {
    const valueA = a[field] ?? 0
    const valueB = b[field] ?? 0

    return ascending ? valueA - valueB : valueB - valueA
  }

export const compareDates =
  (field: string) => (ascending: boolean) => (a, b) => {
    const valueA = new Date(a[field] ?? 0).getTime()
    const valueB = new Date(b[field] ?? 0).getTime()

    return ascending ? valueA - valueB : valueB - valueA
  }
