import { memo, useCallback } from 'react'
import { ScreenReaderContent, Table } from '@instructure/ui'
import { ColumnConfig, RowAttributes } from './types'

export type Sort = {
  by?: string
  ascending?: boolean
}

const getSortDirection = (columnId: string, sort?: Sort) => {
  if (columnId !== sort?.by) return 'none'
  return sort?.ascending ? 'ascending' : 'descending'
}

type Props<T> = {
  columns: ColumnConfig<T>[]
  sort?: Sort
  onSortChange?: (newSort: Sort) => void
  rowAttributes?: () => RowAttributes
}

const Head = memo(
  <T,>({ columns, sort, onSortChange, rowAttributes }: Props<T>) => {
    const handleRequestSort = useCallback(
      (_, { id }) => {
        onSortChange({
          by: id,
          ascending: sort.by === id ? !sort.ascending : true,
        })
      },
      [sort, onSortChange]
    )

    return (
      <Table.Head
        renderSortLabel={<ScreenReaderContent>Sort by</ScreenReaderContent>}
      >
        <Table.Row {...rowAttributes?.()}>
          {columns.map((column) => (
            <Table.ColHeader
              key={column.id}
              id={column.id}
              textAlign={column.align}
              width={column.width}
              sortDirection={getSortDirection(column.id, sort)}
              onRequestSort={
                // Hides sorting controls if no onSortChange provided or specific column.noSort = true
                !onSortChange || column.noSort ? undefined : handleRequestSort
              }
            >
              {column.title}
            </Table.ColHeader>
          ))}
        </Table.Row>
      </Table.Head>
    )
  }
)
Head.displayName = 'Head'

export { Head }
