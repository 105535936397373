import { AssessmentInfo } from 'mc-resources/classroom/ClassroomResource'
import { AbstractResource } from '../AbstractResource'
import { camelizeKeys } from '../../mc-sdk/case-utils'

export type CurriculumMapEditRequestBody = {
  curriculumMap: {
    title: string
    description?: string
    collectionId: number
    restrictCloning: boolean
  }
  ownerId: string
}

export type CurriculumMapCreateRequestBody = {
  curriculumMap: {
    title: string
    description?: string
    subjectId: number
    pathwayId: number
    classObjectiveId: number
    collectionId: number
    restrictCloning: boolean
    scaleId?: number
  }
  ownerId: string
  materialId?: number
}

export type CurriculumMapCreateResponseBody = {
  curriculumMapId: number
}

export type NonDeliverableAssessments = {
  nonDeliverableAssessments: AssessmentInfo[]
}

class CurriculumMapResource extends AbstractResource {
  _endpointUrl = '/curriculum_maps'
  _snakeCaseKeys = true

  update(
    data: CurriculumMapEditRequestBody,
    resourceId: string | number
  ): Promise<void> {
    return super.update(data, resourceId)
  }

  create(
    data: CurriculumMapCreateRequestBody
  ): Promise<CurriculumMapCreateResponseBody> {
    return super.create(data)
  }

  nonDeliverableAssessments(
    cmapId: number | string
  ): Promise<NonDeliverableAssessments> {
    return this.fetchResource(
      this._endpointUrl + `/${cmapId}/non_deliverable_assessments`,
      { method: 'GET' }
    ).then((data) => {
      const camelizedData: NonDeliverableAssessments = camelizeKeys(data)
      return camelizedData
    })
  }
}

export { CurriculumMapResource }
