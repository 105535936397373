import { memo } from 'react'
import { Table } from '@instructure/ui'
import { ColumnConfig, RowAttributes } from './types'

type Props<T> = {
  item: T
  columns: ColumnConfig<T>[]
  rowAttributes?: (item: T) => RowAttributes
}

const Row = memo(<T,>({ item, columns, rowAttributes }: Props<T>) => {
  return (
    <Table.Row {...rowAttributes?.(item)}>
      {columns.map((column) => (
        <Table.Cell
          key={column.id}
          textAlign={column.align}
          data-styles-border-bottom={column.showBorderBottom?.(item)}
        >
          {column.render(item)}
        </Table.Cell>
      ))}
    </Table.Row>
  )
})
Row.displayName = 'Row'

export { Row }
