import { Controller, FieldError, useFormContext } from 'react-hook-form'
import type { FormMessage } from '@instructure/ui-form-field'
import { selectorAttrs } from '../../../../../mc-ui/utils/selectorAttrs'
import { SimpleSelect, Spinner, Text } from '@instructure/ui'
import { defaultValue, classInputName } from '../../../helpers/constants'
import { useClassObjectivesData } from '../../../hooks/useClassObjectivesData'
import { RequiredLabel } from '../../RequiredLabel'

interface Props {
  classObjectiveId: string
  subjectId: string
  pathwayId: string
}

export const ClassSelector = ({
  classObjectiveId,
  subjectId,
  pathwayId,
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext()

  const { classObjectivesData, isGetClassObjectivesDataFetching } =
    useClassObjectivesData(pathwayId, subjectId)

  const classObjectives = classObjectivesData
    ? classObjectivesData.classObjectives
    : []

  const isLoading =
    subjectId !== defaultValue &&
    pathwayId !== defaultValue &&
    isGetClassObjectivesDataFetching

  const isDisabled =
    isGetClassObjectivesDataFetching ||
    subjectId === defaultValue ||
    pathwayId === defaultValue

  function formatErrorMessage(errorMessage: string): FormMessage[] {
    return [
      {
        text: (<Text>{errorMessage}</Text>) as React.ReactNode,
        type: 'error',
      },
    ]
  }

  function handleErrorMessages(error: FieldError): FormMessage[] {
    if (error?.type === 'minLength' || error?.type === 'validate') {
      const message = 'Class cannot be blank'
      return formatErrorMessage(message)
    }
    return []
  }

  return (
    <Controller
      name={classInputName}
      defaultValue={classObjectiveId}
      rules={{
        minLength: 1,
        required: true,
        validate: (value) => value !== defaultValue,
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <SimpleSelect
          renderLabel={RequiredLabel('Class')}
          id={classInputName}
          value={value}
          onChange={(event: React.SyntheticEvent, data) => {
            onChange(data.value as string)
          }}
          onBlur={onBlur}
          inputRef={ref}
          messages={handleErrorMessages(errors[classInputName] as FieldError)}
          {...selectorAttrs('cmap-class-input')}
          disabled={isDisabled}
          renderBeforeInput={
            isLoading ? <Spinner renderTitle="Loading" size="x-small" /> : null
          }
        >
          <SimpleSelect.Option
            id={defaultValue}
            value={defaultValue}
            {...selectorAttrs('class-input-default-option')}
          >
            Select Class
          </SimpleSelect.Option>
          {(classObjectives || []).map(({ id, name }) => (
            <SimpleSelect.Option
              key={id}
              id={String(id)}
              value={String(id)}
              {...selectorAttrs(`class-input-${id}-option`)}
            >
              {name}
            </SimpleSelect.Option>
          ))}
        </SimpleSelect>
      )}
    />
  )
}
