import { Flex, Text, View } from '@instructure/ui'
import TitleInput from '../TitleInput'
import DescriptionInput from '../DescriptionInput'
import { DistrictApprovedSwitch } from '../DistrictApprovedSwitch'
import { PrivacySelector } from '../PrivacySelector'
import { AlignmentFieldsSelector } from '../AlignmentFieldsSelector'
import { ScaleSelector } from '../ScaleSelector/ScaleSelector'
import {
  useHasDistrictRoles,
  useScales,
  useGrade,
  useSubject,
  useCore,
  useShowDistrictApproved,
  useScaleId,
} from '../../../../mc-app/curriculum-map/contexts/CmapTrackerContext'
import { SelectedAlignments } from '../../../../mc-app/classrooms/NewTrackerModal/SelectedAlignments'

export const CurriculumMapNewForm = () => {
  const scales = useScales()
  const hasDistrictRoles = useHasDistrictRoles()
  const grade = useGrade()
  const subject = useSubject()
  const core = useCore()
  const showDistrictApproved = useShowDistrictApproved()
  const scaleId = useScaleId()

  const borderSeperator = (
    <View
      as={'div'}
      borderWidth={'small 0 0 0'}
      width={'100%'}
      margin="0 0 medium"
    />
  )

  return (
    <Flex as={'div'} direction="column" alignItems="start" width="23rem">
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <TitleInput />
      </View>
      <View as={'div'} margin="0 0 medium" width={'100%'}>
        <DescriptionInput />
      </View>
      {borderSeperator}
      {grade && subject && core ? (
        <SelectedAlignments />
      ) : (
        <AlignmentFieldsSelector />
      )}
      {borderSeperator}
      {hasDistrictRoles && scales?.length > 1 ? (
        scaleId ? (
          <View
            background="secondary"
            as="div"
            width={'100%'}
            borderRadius="medium"
            margin="0 0 medium"
          >
            <Flex
              as={'div'}
              direction="column"
              alignItems="start"
              padding="small"
              gap="x-small"
            >
              <Text>Selected scale:</Text>
              <Text weight="bold">
                {scales.find((scale) => scale.id === scaleId).name}
              </Text>
            </Flex>
          </View>
        ) : (
          <>
            <View as={'div'} margin="0 0 medium" width={'100%'}>
              <ScaleSelector />
            </View>
            {borderSeperator}
          </>
        )
      ) : null}
      <PrivacySelector />
      {showDistrictApproved && (
        <>
          {borderSeperator}
          <DistrictApprovedSwitch />
        </>
      )}
    </Flex>
  )
}
